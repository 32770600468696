export const getPortalSubscription = async () => {

  let headers = { 'Content-Type': 'application/json', "Semabench-Token": "ZoQhXcMolvvjYKjEvZ3GO54bbi1EvU2Hh5qRt95anX6eW1959+clS4hgDESf/vTK6w==", "Cache-Control": "max-age=0" };
  try {
    const response = await fetch("../admin/api/v1/portal_subscription", {
      method: 'GET',
      headers: headers
    });
    const result = await response.json();

    if (result !== null && result !== undefined) {
      if (result.end_date !== undefined && result.end_date !== null) {
        let end_date = new Date(result.end_date);
        let todays_date = new Date();
        // Checks if todays date is greater than the expired Date
        if (todays_date > end_date) {
          return 0;
        } else {
          return 1;
        }
      } else if (result.not_valid) {
        return 0;
      }
    }

    //return null if no token
    return 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};
