import { getToken, decrypt_storage_values } from "../ressources/functions/helper";


export function WebSocketSetup() {

    let token = getToken();

    // let login_info = localStorage.getItem("login")
    let login_info = localStorage.getItem("L");

    if (token != null && token !== undefined && login_info !== undefined && login_info != null) {
        login_info = JSON.parse(decrypt_storage_values(login_info));

        var loc = window.location, new_uri;

        if (loc.protocol.includes("https")) {
            new_uri = "wss:";
        } else {
            new_uri = "ws:";
        }

        new_uri += "//" + loc.host;
        new_uri += "/api/ws/?semabench-token=" + encodeURIComponent(login_info.username);

        const socket = new WebSocket(new_uri);
        socket.onopen = function (e) {
        };

        socket.onmessage = function (event) {
            try {
                let message = JSON.parse(event.data);
                if (message !== undefined && message !== null) {
                    if (message.t !== undefined && message.t !== null) {
                        if (message.t === "update_complete" && !window.location.href.includes("version")) {
                            window.location.replace("/logout?upgrade");
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };

        // socket Handler if we loose connection from server
        socket.onclose = async function (event) {
            setTimeout(async () => {
                WebSocketSetup();
            }, 3000)
        };

        socket.onerror = function (event) {
            console.log("Error Connecting to Socket");
        }

    }

}
