// Creating functions todo the search

import { toaster, Message } from 'rsuite';
import { isArray } from 'util';
import { getAlbumNodes } from '../functions/AlbumAPIs.js';
import { checkAcl, checkMultiCampus, getMultiCampuslist } from "../../adminressources/ACLHelper.js";
import { getToken, getConfig, getBin, getInfo, getQuery, Check_login_ticket, decrypt_storage_values } from './helper.js';

// Initial state of Advance Query
export const defaultAdvanced = {
    and: true,
    field: "",
    field_selected: false,
    op: "~=",
    value: ""
}

// initial State of the Query object
export const initialState = {
    loading: false,
    loaded: false,
    failed: false,
    initial: false,
    advanced_query: [{ ...defaultAdvanced }],
    advanced_pristine: true,
    facets: {},
    facet_counts: {},
    num_found: 0,
    docs: [],
    suggestions: [],
    error: {},
    query_text: "",
    rows: 200
};

export var Query = initialState;

// Sets the default facet limit to 1000
export const FACET_LIMIT = 10000;

// const query_pattern = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"])/g;
const query_pattern = /([!*+-=<>&|()[]{}^~?:\/"])/g;

// Sanitise Query method for removing and changing query to yaml readable
export const sanitiseQuery = (query) => {
    let q = String(query);
    return q.replace(query_pattern, "\\$1");
}

// returns the suggested Query fields and query fields used by solr
const getQueryFields = (config, info) => {

    let returnFields = "suggest^10";

    let result_view_fields = config.result_views.map(view => {

        let fields = [];

        fields = fields.concat(view.heading);

        if (view.sub_heading !== undefined && view.sub_heading !== null) {
            fields = fields.concat(view.sub_heading);
        }

        if (view.first_row !== undefined && view.first_row !== null) {
            fields = fields.concat(view.first_row);
        }

        if (view.second_row !== undefined && view.second_row !== null) {
            fields = fields.concat(view.second_row);
        }


        return fields;
    }).reduce((a, b) => a.concat(b), []);

    result_view_fields = result_view_fields.filter(field => info.fields[field] !== undefined && info.fields[field] !== null && info.fields[field].type === "text").map(field => field + "_q");


    returnFields += " " + result_view_fields.join(" ");

    if (config.suggest_field !== undefined && config.suggest_field !== null) {
        returnFields += " " + config.suggest_field;
    }


    return returnFields;
}


export const convertToLucence = (query, fields) => {
    // For each row (used for advance) returns a solr readable query from user input
    return query.filter(row => row.value !== "" && row.field_selected === true)
        .map((row) => {
            let return_row = { ...row };
            // Converts the date to ISO string if the value is a date
            if (fields[row.field] !== null && fields[row.field].type === "date" && (row.op === ">=" || row.op === "<=")) {
                let date_value = row.value;
                try {
                    return_row.value = date_value
                } catch {
                    return_row.value = ""
                }
            }


            return return_row
        })
        .filter(row => row.value !== "")
        .map((row, index) => {
            let return_query = "";

            if (index > 0) {
                return_query += row.and ? "AND " : "OR ";
            }

            let type = getType(fields[row.field]);

            // Depending on the operator used, will create the query
            if (row.field !== undefined && row.value !== undefined && row.field !== null && row.value !== null) {
                switch (row.op) {
                    default:
                        break;
                    case "~=":

                        if (type === "text" && row.field !== "path_starts_with" && row.field !== "path") {
                            return_query += `${row.field}:*${row.value.replace(/\s/g, "\\ ")}*`
                        } else {
                            return_query += `${row.field}:*${row.value.replace(/\s/g, "\\ ")}*`
                        }
                        break;

                    case "==":
                        if (row.value === "*" && type === "number") {
                            row.value = "[* TO *]";
                        }
                        try {
                            return_query += row.value.indexOf("*") !== -1 ? `${row.field}:${row.value}` : `${row.field}:"${row.value}"`
                        } catch {
                            return_query += `${row.field}:${row.value}`
                        }
                        break;

                    case "!=":

                        if (row.value === "*" && (type === "number" || type === "long")) {
                            row.value = "[* TO *]";
                        }

                        //Solr automatically expands -field:* to (*:* NOT field:*) but ONLY for the first statement... 
                        return_query += row.value.indexOf("*") !== -1 ? `(*:* NOT ${row.field}:${row.value})` : `(*:* NOT ${row.field}:"${row.value}")`
                        break;

                    case ">=":
                        return_query += `${row.field}:[${row.value} TO *]`
                        break;

                    case "<=":
                        return_query += `${row.field}:[* TO ${row.value}]`
                        break;
                }
            }

            return return_query;
        })
        .join(" ");
}

// allowed operator types
export const allowed_types = {
    text: ['~=', '==', '!='],
    select: ['==', '!='],
    boolean: ['=='],
    date: ['>=', '<='],
    number: ['==', '!=', '>=', '<=']
};

// labels for each operator types
export const allowed_types_selector = {
    text: [{ label: "Contains", value: '~=' }, { label: "Equals", value: '==' }, { label: "Does Not Equal", value: '!=' }],
    select: [{ label: "Equal", value: '==' }, { label: "Not Equal", value: '!=' }],
    boolean: [{ label: "Equal", value: '==' }],
    date: [{ label: "Greater Than or Equal To", value: '>=' }, { label: "Less Than or Equal To", value: '<=' }],
    number: [{ label: "Equals", value: '==' }, { label: "Not Equal", value: '!=' }, { label: "Greater Than or Equal To", value: '>=' }, { label: "Less than or Equal To", value: '<=' }]
};

// Gets the type of field and returns the type matched in alfresco
export const getType = (info) => {
    let field_type = "text";
    if (info !== undefined && info !== null) {
        if (info.constraints && info.constraints.list) {
            field_type = "select";
        } else if (info.type === "path") {
            field_type = "text";
        } else if (info.type === "boolean") {
            field_type = "boolean";
        } else if (info.type === "double" || info.type === "long" || info.type === "int") {
            field_type = "number";
        } else if (info.type === "date") {
            field_type = "date";
        }
    }

    return field_type;
}

// convert filters to string query
export const filterToString = (filter, fields) => {
    let val;

    let join = " OR ";

    if (filter.type !== undefined && filter.type !== null) {
        join = " " + filter.type + " ";
    }

    // Skip sanitisation rules if field type is "date" or field name is "id"
    if ((fields[filter.field_name] !== undefined && fields[filter.field_name].type === "date") || filter.field_name === "id" || (filter.value.length > 0 && filter.value[0] === ".*")) {
        if (filter.value !== undefined && filter.value !== null) {
            val = `(${filter.value.join(join)})`;
        }

    } else {
        val = `(${filter.value.map(val => `"${sanitiseQuery(val)}"`).join(join)})`;
    }

    return `{!tag=${filter.field_name}}${filter.inverse ? "-" : "+"}${filter.field_name}:${val}`;

}

// Initial field list
export const fieldList = (fields) => {
    return Object.keys(fields).concat(["id", "type", "path", "cm_name", "preview_ref", "preview_kind", "preview_type", "sb_tags", "sb_publisedTo"]);
}

export async function submitSolrQuery(type, selectedRows, id) {
    // Updates suggestive search everytime a query is applied
    if (type !== "upload" && type !== "album" && type !== "collection") {
        updateSearchSuggestions();
    }

    // Loads all the config, info and currenty query
    let config = getConfig();

    // loads the nodes that are within the bin
    let bin = getBin();

    // Loads the Curation album UAID
    let uaid = sessionStorage.getItem("S");
    if (uaid == null) {
        uaid = 1;
    }
    let classify = [];

    // if not accessing upload, bin or curation. Will add the curation and bin nodes to a filter to ignore them on lookup
    if (type === undefined || type === null || (type !== "bin" && type !== "classify" && type !== "upload" && type !== "album")) {
        await getAlbumNodes(uaid).then(result => {
            classify = result;

            if (classify !== undefined && classify !== null) {
                try {
                    classify.forEach(doc => {
                        bin.push(doc);
                    })
                } catch (e) {

                }
            }
        })
    }

    // if accessing the bin, does a search on only the bin nodes
    if (type === "bin") {
        config.view.filters.push({ field_name: "id", value: bin, inverse: false })
    }

    // Add a solr filter if its a bin list
    if (type !== "bin" && type !== "classify" && type !== "upload" && type !== "album" && type !== "collection") {
        if (bin !== undefined && bin !== null) {
            if (bin.length !== 0) {
                config.view.filters.push({ field_name: "id", value: bin, inverse: true })
            }
        }
    }


    let view = config.view;
    let info = getInfo();

    // Loads the current saved query
    let current_q = getQuery();
    if (current_q === undefined || current_q === null) {
        current_q = initialState
    }


    // adds the fild lists into the URL
    let fl = encodeURIComponent(fieldList(info.fields).join(","));

    // Gets the Solr query info
    let query = view.query;
    let q = query === "" ? "*:*" : query.indexOf(":") !== -1 ? query : sanitiseQuery(query);

    // excludes bin items
    if (id !== undefined && id !== null) {
        q = "id:(" + id + ")";
    }

    if (type !== "collection") {
        getFacets(true, q, config, type);
    }

    let filters = [config.default_filter];

    let applied_filter_rules = [];

    let filter_rules = view.filter_rules || [];

    filter_rules.forEach(filter_rule => {
        if (config.filter_rules[filter_rule] !== undefined && config.filter_rules[filter_rule] !== null) {
            applied_filter_rules.push(config.filter_rules[filter_rule]);
        }
    });

    let sort = view.sort.field + " " + view.sort.direction;

    let rows = current_q.rows;

    if (selectedRows !== undefined && selectedRows !== null) {
        rows = selectedRows;
    }

    // Sets the amount of rows by default for bin and event
    if (type === "event" || type === "bin") {
        rows = 100000;
    }

    let fields_info = info.fields;
    let query_facets = view.facets;

    // Creates the facet query based on the facets added.
    let facets = query_facets.filter(facet => fields_info[facet] !== undefined).filter(facet => fields_info[facet].type !== "date");

    let date_type_fields = query_facets.filter(facet => fields_info[facet] !== undefined).filter(facet => fields_info[facet].type === "date");


    let facet_query_field = date_type_fields.map(encodeURIComponent).map(field =>
        [field + ":[NOW-1DAY TO NOW]", field + ":[NOW-7DAYS TO NOW]", field + ":[NOW-1MONTH TO NOW]", field + ":[NOW-1YEAR TO NOW]", field + ":[* TO NOW-1YEAR]"].map(query => `facet.query={!ex=${field}}${query}`).join("&")
    ).join("&");

    filters = filters.concat(view.filters
        .filter(filter => filter.field_name !== "Advanced" && filter.value.length > 0)
        .map(filter => filterToString(filter, info.fields)).concat(applied_filter_rules.map(filter => filterToString(filter, info.fields))));

    let facetOptions = "facet.limit=" + (FACET_LIMIT + 1);

    Object.entries(Query.facet_counts).forEach(fc => {
        facetOptions += `&f.${fc[0]}.facet.limit=${fc[1] + 1}`;
    })

    filters = filters.concat(view.filters.filter(filter => filter.field_name === "Advanced").map(filter => isArray(filter.value) ? filter.value.join(" ") : filter.value));

    // Options of setting path starts with
    if (facets.indexOf("path_starts_with") !== -1) {
        let facet_field = view.filters.find(filter => filter.field_name === "path_starts_with");

        let prefix = facet_field !== undefined ? (isArray(facet_field.value) ? facet_field.value.join(" ") : facet_field.value) : config.path_strip;

        facetOptions += "&f.path_starts_with.facet.limit=1000";
        facetOptions += "&f.path_starts_with.facet.mincount=0";
        facetOptions += "&f.path_starts_with.facet.sort=index";
        facetOptions += "&f.path_starts_with.facet.prefix=" + encodeURIComponent(prefix);
    }

    let token = getToken();

    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    let qf = encodeURIComponent(getQueryFields(config, info));
    let facet = {};

    facets.forEach(val => {

        let limit = (Query.facet_counts[val] || FACET_LIMIT) + 1;
        facet[val] = {
            type: "terms",
            field: val,
            sort: val === "sb_year" ? "index desc" : undefined,
            domain: {
                excludeTags: val
            },
            limit,
        }
    })

    facet_query_field = facet_query_field === "" ? "" : "&" + facet_query_field;

    if (type === "upload") {
        q = "*:*";
    }

    //applying solr query based on the identified campus
    // let login = JSON.parse(localStorage.getItem("login"));
    let login = localStorage.getItem("L");
    if (login !== undefined && login !== null) {
        login = JSON.parse(decrypt_storage_values(login));

        if (!checkAcl(login.roles, config.acls, "ADMIN") && type !== "album" && type !== "favourite" && type !== "upload" && type !== "collection") {
            // update the solr query
            if (checkMultiCampus()) {

                let campus = getMultiCampuslist();

                //create new query with uploaderCampus and update the query
                let new_q = "";
                for (let y = 0; y < campus.length; y++) {
                    if (y + 1 === campus.length) {
                        new_q += "sb_uploaderCampus:\"" + campus[y] + "\""
                    } else {
                        new_q += "sb_uploaderCampus:\"" + campus[y] + "\" OR "
                    }

                }

                if (q === "" || q === undefined || q === null || q === "*:*") {
                    q = new_q
                } else {
                    q = "\"" + q + "\" AND (" + new_q + ")"
                }
            }
        }
    }

    let body = JSON.stringify({
        query: q,
        filter: filters,
        facet: facet
    })

    // Added a start index. More you scroll it creates a offset so the API only ever get 200 items at a time
    let selected = 0;
    if (selectedRows !== undefined && selectedRows !== null && type !== "upload") {
        selected = selectedRows - 200
        rows = 200;
    }

    if (type === "upload") {
        rows = selectedRows
    }

    if (selected < 0) {
        selected = 0;
    }

    let result = await fetch(`../api/v1/repo/s/semabench/query?q.op=AND&omitHeader=true&defType=edismax&qf=${qf}&pf=${qf}&start=${selected}&df=suggest&indent=true&sow=false&wt=json&json.nl=map&sort=${sort}&fl=${fl}&rows=${rows}&facet=true&facet.mincount=1&${facetOptions}` + facet_query_field, {
        method: "POST",
        headers,
        body
    }).catch(error => {
        toaster.push(<Message type='error' showIcon closable>{error}</Message>);
    })

    //redirect the users to the login pages if the login token is invalid
    if (result.status === 401) {
        Check_login_ticket()
    }

    return await result.json().then(async resp => {
        return await loadingFields(resp, type);
    })
}


// Does a query search to get all the facets, regardless of 200 to fill out search drop downs
export async function getFacets(upload, filterQuery, qconfig, type, classify) {
    let config = getConfig();

    if (qconfig !== undefined && qconfig !== null) {
        if (type !== undefined && type !== null) {
            if (type !== "classify") {
                config = qconfig;
            }
        }
    }

    // load the bin from sessionStorage
    let bin = getBin();
    // Loads the classify album uaid from local storage
    let uaid = sessionStorage.getItem("S");

    // Loads the Classify album
    if (classify === undefined || classify === null) {
        await getAlbumNodes(uaid).then(result => {
            classify = result;
            if (classify !== undefined && classify !== null) {
                try {
                    // for each doc we add into the bin list
                    classify.forEach(doc => {
                        bin.push(doc);
                    })
                } catch (e) {

                }
            }
        })
    }


    // if not upload adds all the ids as a filter
    if (!upload) {
        // Adds the ids as a filter to remove them (bin/curation items) from response
        if (bin !== undefined && bin !== null) {
            if (bin.length !== 0) {
                await getAlbumNodes(1).then(resp => {
                    let new_value = [...resp, ...bin]
                    config.view.filters.push({ field_name: "id", value: new_value, inverse: true })
                })
                // config.view.filters.push({ field_name: "id", value: bin, inverse: true })
            } else {
                await getAlbumNodes(1).then(resp => {
                    if (resp !== undefined && resp !== null) {
                        if (resp.length > 0) {
                            config.view.filters.push({ field_name: "id", value: resp, inverse: true })
                        }
                    }
                })
            }
        } else {
            await getAlbumNodes(1).then(resp => {
                if (resp !== undefined && resp !== null) {
                    if (resp.length > 0) {
                        config.view.filters.push({ field_name: "id", value: resp, inverse: true })
                    }
                }
            })
        }
    }


    let info = getInfo();
    let fields_info = info.fields;

    let facet = {};
    let token = getToken();

    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    let query = config.view.query;
    let q = query === "" ? "*:*" : query.indexOf(":") !== -1 ? query : sanitiseQuery(query);

    if (filterQuery !== undefined && filterQuery !== null && filterQuery !== "") {
        q = filterQuery
    }

    let query_facets = config.view.facets;

    // Creates the facet query based on the facets added.
    let facets = query_facets.filter(facet => fields_info[facet] !== undefined).filter(facet => fields_info[facet].type !== "date");

    let date_type_fields = query_facets.filter(facet => fields_info[facet] !== undefined).filter(facet => fields_info[facet].type === "date");

    let facet_query_field = date_type_fields.map(encodeURIComponent).map(field =>
        [field + ":[NOW-1DAY TO NOW]", field + ":[NOW-7DAYS TO NOW]", field + ":[NOW-1MONTH TO NOW]", field + ":[NOW-1YEAR TO NOW]", field + ":[* TO NOW-1YEAR]"].map(query => `facet.query={!ex=${field}}${query}`).join("&")
    ).join("&");

    facets.forEach(val => {

        let limit = (Query.facet_counts[val] || FACET_LIMIT) + 1;
        facet[val] = {
            type: "terms",
            field: val,
            sort: val === "sb_year" ? "index desc" : undefined,
            domain: {
                excludeTags: val
            },
            limit,
        }
    })

    let body;

    let filters = [config.default_filter];

    let applied_filter_rules = [];


    let filter_rules = config.view.filter_rules || [];

    filter_rules.forEach(filter_rule => {
        if (config.filter_rules[filter_rule] !== undefined && config.filter_rules[filter_rule] !== null) {
            applied_filter_rules.push(config.filter_rules[filter_rule]);
        }
    });

    filters = filters.concat(config.view.filters
        .filter(filter => filter.field_name !== "Advanced")
        .map(filter => filterToString(filter, info.fields)).concat(applied_filter_rules.map(filter => filterToString(filter, info.fields))));

    filters = filters.concat(config.view.filters.filter(filter => filter.field_name === "Advanced").map(filter => isArray(filter.value) ? filter.value.join(" ") : filter.value));

    if (upload) {
        filters = [config.default_filter];
        q = "*:*";
    } else if (type !== undefined && type !== null) {
        if (type === "classify") {
            filters = [config.default_filter];
            q = "*:*";
        }
    }

    //applying solr query based on the identified campus
    // let login = JSON.parse(localStorage.getItem("login"));
    let login = localStorage.getItem("L");
    if (login !== undefined && login !== null) {
        login = JSON.parse(decrypt_storage_values(login));
        if (!checkAcl(login.roles, config.acls, "ADMIN") && type !== "album" && type !== "favourite" && type !== "upload") {
            //update the solr query
            if (checkMultiCampus()) {
                let campus = getMultiCampuslist()
                let new_q = ""
                for (let y = 0; y < campus.length; y++) {
                    if (y + 1 === campus.length) {
                        new_q += "sb_uploaderCampus:\"" + campus[y] + "\""
                    } else {
                        new_q += "sb_uploaderCampus:\"" + campus[y] + "\" OR "
                    }

                }
                q = new_q
            }
        }
    }

    if (filters !== undefined && filters !== null) {
        body = JSON.stringify({
            query: q,
            filter: filters,
            facet: facet
        })
    } else {
        body = JSON.stringify({
            query: q
        })
    }

    let qf = encodeURIComponent(getQueryFields(config, info));

    let facetResp = {};
    facet_query_field = facet_query_field === "" ? "" : "&" + facet_query_field;
    await fetch(`../api/v1/repo/s/semabench/query?q.op=AND&omitHeader=true&defType=edismax&qf=${qf}&pf=${qf}&df=suggest&indent=true&sow=false&wt=json&json.nl=map&facet=true&rows=0&json.nl=map` + facet_query_field, {
        method: 'POST',
        headers: headers,
        body: body
    }).then(resp => resp.json())
        .then(response => {
            // Depedns on the results if there were filters or not
            if (response.facet_counts) {

                if (response.facet_counts.facet_fields) {
                    facetResp = response.facet_counts.facet_fields;
                }

                if (response.facet_counts.facet_queries) {
                    let facet_queries = response.facet_counts.facet_queries;
                    Object.keys(facet_queries)
                        .filter(facet_query => facet_query.indexOf(":") > 0)
                        .forEach(facet_query => {
                            let splits = facet_query.split(":");
                            let facet_field_parts = splits[0];

                            //Filter out our facet tag
                            let facet_field = facet_field_parts.replace(/{!ex=[^}]+}/g, "");

                            let val = splits[1];
                            let count = facet_queries[facet_query];

                            if (!facetResp[facet_field]) {
                                facetResp[facet_field] = [];
                            }

                            facetResp[facet_field].push({ val, count });
                        });
                }
            }


            if (response.facets) {
                let facet_resp = response.facets;
                Object.keys(facet_resp).filter(val => val !== "count")
                    .map(facet_field => {
                        let facet = facet_resp[facet_field];

                        if (!facetResp[facet_field]) {
                            facetResp[facet_field] = [];
                        }

                        facet.buckets.forEach((bucket) => {
                            facetResp[facet_field].push({
                                val: bucket.val + "",
                                count: bucket.count
                            });
                        });
                        return facet
                    });
            }
        })

    if (Object.keys(facetResp).length !== 0) {
        //checking the size of localstorage & sessionstorage
        // var _lsTotal = 0,
        //     _xLen, _x;
        // for (_x in sessionStorage) {
        //     if (!sessionStorage.hasOwnProperty(_x)) {
        //         continue;
        //     }
        //     _xLen = ((sessionStorage[_x].length + _x.length) * 2);
        //     _lsTotal += _xLen;
        //     console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
        // };
        // console.log("sessionStorage Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
        // var _lsTotal = 0,
        //     _xLen, _x;
        // for (_x in localStorage) {
        //     if (!localStorage.hasOwnProperty(_x)) {
        //         continue;
        //     }
        //     _xLen = ((localStorage[_x].length + _x.length) * 2);
        //     _lsTotal += _xLen;
        //     console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
        // };
        // console.log("localStorage Total = " + (_lsTotal / 1024).toFixed(2) + " KB");

        localStorage.setItem("F", JSON.stringify(facetResp));

    }
}

// Gets called to update search suggestion in dropdown after a suer starts typing
export async function updateSearchSuggestions() {
    let config = getConfig();
    let query = config.view.query.toLowerCase();

    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    let filters = [config.default_filter];

    let info = getInfo()

    let body = JSON.stringify({
        filter: filters
    })

    filters = filters.concat(config.view.filters
        .filter(filter => filter.field_name !== "Advanced")
        .map(filter => filterToString(filter, info.fields)));

    if (query === "*:*") {
        query = "";
    }

    await fetch(`../api/v1/repo/s/semabench/query?q=*:*&fl=id&json.nl=map&rows=0&facet.limit=10&facet=true&facet.mincount=1&facet.prefix=${query}&facet.field=suggest`, {
        method: "POST",
        headers,
        body
    }).then(resp => resp.json())
        .then(result => {

            let facets = result.facet_counts.facet_fields
            let suggest = facets["suggest"]


            let suggestions = []
            if (suggest !== undefined && suggest !== null) {
                suggestions = Object.keys(suggest);
            }

            config.suggestions = suggestions

            sessionStorage.setItem("C", JSON.stringify(config));
        }).catch(error => {

        })
}

// Loads the fields and data of each results
export async function loadingFields(response, type) {
    let docs = [];
    let num_found = 0;
    let facets = {};
    let q = JSON.parse(sessionStorage.getItem("Q"));
    if (q !== undefined && q !== null) {
        Query.advanced_query = q.advanced_query;
    }
    // let query_text = Query.query_text;

    if (response && response.response) {
        if (response.response.docs) {
            docs = response.response.docs;
        }
        if (response.response.numFound) {
            num_found = response.response.numFound;
        }
        if (response.facet_counts) {

            if (response.facet_counts.facet_fields) {
                facets = response.facet_counts.facet_fields;
            }

            if (response.facet_counts.facet_queries) {
                let facet_queries = response.facet_counts.facet_queries;
                Object.keys(facet_queries)
                    .filter(facet_query => facet_query.indexOf(":") > 0)
                    .forEach(facet_query => {
                        let splits = facet_query.split(":");
                        let facet_field_parts = splits[0];

                        //Filter out our facet tag
                        let facet_field = facet_field_parts.replace(/{!ex=[^}]+}/g, "");

                        let val = splits[1];
                        let count = facet_queries[facet_query];

                        if (!facets[facet_field]) {
                            facets[facet_field] = [];
                        }

                        facets[facet_field].push({ val, count });
                    });
            }
        }

        if (response.facets) {

            let facet_resp = response.facets;

            Object.keys(facet_resp).filter(val => val !== "count")
                .map(facet_field => {
                    let facet = facet_resp[facet_field];

                    if (!facets[facet_field]) {
                        facets[facet_field] = [];
                    }

                    facet.buckets.forEach((bucket) => {
                        facets[facet_field].push({
                            val: bucket.val + "",
                            count: bucket.count
                        });
                    });
                    return facet
                });
        }

        Query.docs = docs;
        // Query.facets = facets;
        Query.num_found = num_found;
        Query.failed = false;
        Query.loading = false;
        Query.loaded = true;
        Query.initial = true;
        Query.facet_loading_field = undefined



        // let save = "";
        try {
            switch (type) {
                case "favourite":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "album":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "classify":
                    // save = "query-" + type
                    // try {
                    // localStorage.setItem(save, JSON.stringify(Query));
                    // } catch (e) {

                    // };

                    break;
                case "upload":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "bin":
                    // try {
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    // } catch (e) { }

                    break;
                case "selected_profile":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "related":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "profile":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "events":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "image":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;
                case "filter":
                    // save = "query-" + type
                    // localStorage.setItem(save, JSON.stringify(Query));
                    break;

                case "collection":
                    break;

                default:
                    try {
                        sessionStorage.setItem("Q", JSON.stringify(Query));
                    } catch (e) { }

                    break;
            }
        } catch (e) {

        }



    }
    return Query;
}


// Function for getting the profiles based on the profile types and max row of profiles needed
export async function getProfileQuery(rows, profileTypes) {

    // Loads all the config, info and currenty query
    let config = getConfig();

    let view = config.view;
    let info = getInfo();

    // Loads the current saved query
    let current_q = getQuery();
    if (current_q === undefined || current_q === null) {
        current_q = initialState
    }


    // adds the fild lists into the URL
    let fl = encodeURIComponent(fieldList(info.fields).join(","));

    // Gets the Solr query info
    let query = view.query;
    let q = query === "" ? "*:*" : query.indexOf(":") !== -1 ? query : sanitiseQuery(query);

    let filters = [];

    let applied_filter_rules = [];

    let filter_rules = [];

    // Creates three filters as we need only the profile type and it being a profile
    config.view.filters = [];
    config.view.filters.push({ field_name: "sb_profileType", value: profileTypes, inverse: false })
    config.view.filters.push({ field_name: "sb_adminStatus", value: ["Profile"], inverse: false })
    config.view.filters.push({ field_name: "sb_adminStatus", value: ["Reference Point"], inverse: true })

    filter_rules.forEach(filter_rule => {
        if (config.filter_rules[filter_rule] !== undefined && config.filter_rules[filter_rule] !== null) {
            applied_filter_rules.push(config.filter_rules[filter_rule]);
        }
    });

    let sort = view.sort.field + " " + view.sort.direction;

    let fields_info = info.fields;
    let query_facets = view.facets;

    // Creates the facet query based on the facets added.
    let facets = query_facets.filter(facet => fields_info[facet] !== undefined).filter(facet => fields_info[facet].type !== "date");

    let date_type_fields = query_facets.filter(facet => fields_info[facet] !== undefined).filter(facet => fields_info[facet].type === "date");


    let facet_query_field = date_type_fields.map(encodeURIComponent).map(field =>
        [field + ":[NOW-1DAY TO NOW]", field + ":[NOW-7DAYS TO NOW]", field + ":[NOW-1MONTH TO NOW]", field + ":[NOW-1YEAR TO NOW]", field + ":[* TO NOW-1YEAR]"].map(query => `facet.query={!ex=${field}}${query}`).join("&")
    ).join("&");

    filters = filters.concat(view.filters
        .filter(filter => filter.field_name !== "Advanced")
        .map(filter => filterToString(filter, info.fields)).concat(applied_filter_rules.map(filter => filterToString(filter, info.fields))));

    let facetOptions = "facet.limit=" + (FACET_LIMIT + 1);

    Object.entries(Query.facet_counts).forEach(fc => {
        facetOptions += `&f.${fc[0]}.facet.limit=${fc[1] + 1}`;
    })

    filters = filters.concat(view.filters.filter(filter => filter.field_name === "Advanced").map(filter => isArray(filter.value) ? filter.value.join(" ") : filter.value));

    // Options of setting path starts with
    if (facets.indexOf("path_starts_with") !== -1) {
        let facet_field = view.filters.find(filter => filter.field_name === "path_starts_with");

        let prefix = facet_field !== undefined ? (isArray(facet_field.value) ? facet_field.value.join(" ") : facet_field.value) : config.path_strip;

        facetOptions += "&f.path_starts_with.facet.limit=1000";
        facetOptions += "&f.path_starts_with.facet.mincount=0";
        facetOptions += "&f.path_starts_with.facet.sort=index";
        facetOptions += "&f.path_starts_with.facet.prefix=" + encodeURIComponent(prefix);
    }

    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    let qf = encodeURIComponent(getQueryFields(config, info));
    let facet = {};

    facets.forEach(val => {

        let limit = (Query.facet_counts[val] || FACET_LIMIT) + 1;
        facet[val] = {
            type: "terms",
            field: val,
            sort: val === "sb_year" ? "index desc" : undefined,
            domain: {
                excludeTags: val
            },
            limit,
        }
    })

    facet_query_field = facet_query_field === "" ? "" : "&" + facet_query_field;

    let body = JSON.stringify({
        query: q,
        filter: filters,
        facet: facet
    })

    body = JSON.stringify({
        "query": "*:*",
        "filter": filters
    })

    let val = undefined
    return await fetch(`../api/v1/repo/s/semabench/query?q.op=AND&omitHeader=true&defType=edismax&qf=${qf}&pf=${qf}&df=suggest&indent=true&sow=false&wt=json&json.nl=map&sort=${sort}&fl=${fl}&rows=${rows}&facet=true&facet.mincount=1&${facetOptions}` + facet_query_field, {
        method: "POST",
        headers,
        body
    }).then(resp => resp.json())
        .then(async re => {
            val = re;
            return await val;
        })
        .catch(error => {
            toaster.push(<Message type='error' showIcon closable>{error}</Message>);
        })
}

// Function for getting the profiles based on the profile types and max row of profiles needed
export async function getImageCount(id) {
    let token = getToken();
    let getBin = JSON.parse(localStorage.getItem("B"));
    getBin.push(id);
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    let campus = getMultiCampuslist();

    // let query = { query: 'sb_adminStatus:"Reference Point" AND sb_relatedID:"' + id + '" AND (*:* NOT id:"' + id + '")' }

    // let referencePoints = [];
    // await fetch('../api/v1/repo/s/semabench/query?q=*:*&rows=1000', {
    //     method: 'POST',
    //     headers: headers,
    //     body: JSON.stringify(query)
    // }).then(resp => resp.json())
    //     .then(async response => {
    //         referencePoints = response.response.docs
    //     })

    //create new query with uploaderCampus and update the query
    // let new_q = "sb_uploaderCampus:" + campus.join(" OR sb_uploaderCampus:")
    let new_q = ""
    for (let y = 0; y < campus.length; y++) {
        if (y + 1 === campus.length) {
            new_q += "sb_uploaderCampus:\"" + campus[y] + "\""
        } else {
            new_q += "sb_uploaderCampus:\"" + campus[y] + "\" OR "
        }

    }

    let body = JSON.stringify({
        query: 'sb_relatedID:"' + id + '" AND (*:* NOT id:"' + getBin.join(' OR ') + '") AND (' + new_q + ")",
        filter: ["{!tag=id}-id:(" + getBin.join(" OR ") + ")",
            "{!tag=sb_adminStatus}-sb_adminStatus:(\"Profile\")",
            "{!tag=sb_adminStatus}-sb_adminStatus:(\"Reference Point\")"]
    })
    // console.log(JSON.parse(body))
    return await fetch('../../api/v1/repo/s/semabench/query?q=*:*&rows=5000', {
        method: 'POST',
        headers: headers,
        body: body
    }).then(resp => resp.json())
        .then(async response => {
            // console.log(response)
            if (response.response !== undefined && response.response !== null) {
                //     let r = response.response.docs;
                //     let docs = r
                // console.log(response.response.docs)
                return response.response.docs.length
            }
        });
    // return true;

}
