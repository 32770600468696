import { toaster, Message, } from 'rsuite';
import { getToken } from './helper';

// API to get album Nodes
export const getAlbumNodes = async(uaid) => {
    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    if(uaid !== undefined && uaid !== null){
        return await fetch("../api/v1/albumnodes?uaid=" + uaid, {
            method: 'GET',
            headers: headers,
        })
            .then(res => res.json())
            .then((result) => {
                return result
            }).catch((error) => {
                console.error('Error:', error);
                return undefined;
            });
    }else{
        return undefined
    }
    
}

// remove the item from the album
export async function removeFromAlbum(uaid, nodes, hide) {
    let token = getToken();
    let header = {
        'Semabench-Token': token,
        'Content-Type': 'application/json'
    }

    if(uaid !== 1){
        // Lets the user know we are removing items from the album
        toaster.clear();
        toaster.push(<Message type='info' showIcon closable>Removing Items...</Message>)
    }
    


    let result = {"uaid": parseInt(uaid), "nodes": nodes }


    // APi to remove from the album
    return await fetch("../api/v1/albumnodes", {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(result)
    }).then(resp => {
        toaster.clear();
        if(uaid !== 1){
            if (hide === undefined || hide === null){
                toaster.push(<Message type='success' showIcon closable>Items Removed</Message>);
            }
        }
    })
    .catch((error) => {
        toaster.push(<Message type='error' showIcon closable>{error}</Message>);
    })

}

export async function updateAlbum(uaid, album) {
    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    fetch("../api/v1/album?uaid=" + uaid, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(album)
    }).then(resp => {
        toaster.clear();
        toaster.push(<Message type='success' showIcon closable>{"Album " + album.name + " Updated."}</Message>);
    })
    .catch((error) => {
        toaster.push(<Message type='error' showIcon closable>{error}</Message>)
    })

}


// Calls the api to get the UAIDs that the node belongs in
export async function getAlbumByNode(node) {
    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    return fetch("../api/v1/album/node?node=" + node, {
        method: 'GET',
        headers: headers
    })
    .then(res => res.json())
    .then((result) => {
        return result
    }).catch((error) => {
        console.error('Error:', error);
        return undefined;
    });
    
}


export async function getAlbums() {
    let token = getToken();
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };
    fetch("../api/v1/user/albums", {
        method: 'GET',
        headers: headers,
    })
    .then(res => res.json())
    .then(result => {
        let accessible_list = [];
        // Update the albums node ids.
        Object.keys(result).forEach( key => {
            if(result[key]) {
                result[key].forEach( uaid => {
                    if (!accessible_list.includes(uaid)) {
                        accessible_list.push(uaid);
                    }
                })
            }
        })
        accessible_list.forEach(uaid => {
            fetch("./api/v1/album?uaid=" + uaid, {
                method: 'GET',
                headers: headers,
            })
                .then(res => res.json())
                .then((result) => {
                    if(result.name === "SB_Classify"){
                        // sets the uaid of the Curation album
                        sessionStorage.setItem("S", result.uaid);
                    }
                }).catch((error) => {
                    console.error('Error:', error);
                });
        })
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}
